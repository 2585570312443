/*.pos-rel-overflow-hide {
  position: relative;
  overflow: hidden;
  // min-height: 50vh;
  min-height: 340px;
  // height: calc(100vh - 170px);
}*/
table {
  .timing-header {
    position: relative;
    background: #fff;
    background-color: #fff;
    /*z-index: 9;*/
    border-top: 1px solid #dee2e6;
  }
}

.table-reservation {
  tr {
    td {
      .custom-control-label::before,
      .custom-control-label::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.add-visitor-table {
  tr {
    td {
      .custom-control {
        text-align: center;
      }
    }
  }
}

.credit-container {
  position: absolute;
  width: 1020px;
  max-width: 100%;
  height: 300px;
  top: -300px;
  right: 5px;
  z-index: 999;
  background: #f2f2f2;
  padding: 15px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 6px 6px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;

  &.open {
    top: 0;
  }

  .btn-credit {
    background: #1dc7ea;
    color: #fff;
    border: none;
    width: 100px;
    transform: rotate(0deg);
    position: absolute;
    bottom: -30px;
    right: 10px;
    height: 30px;
    border-radius: 0 0 6px 6px;
  }

  .credit-ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
          font-size: 14px;

          small {
            display: block;
          }
        }
      }
    }
  }

  .tab-content {
    max-height: 240px;
    font-size: 14px;

    .table-responsive {
      max-height: 220px;
      overflow: auto;

      table {
        margin-bottom: 0;
      }
    }
  }

  .card-header-tabs {
    margin: 0;

    .nav-link {
      border: none;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .avail-credit-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 15px 0;
  }
}

.overflow-auto {
  overflow: auto !important;
}

/*Tickit Section*/
.chatbox {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.chat-msg-scroll {
  height: 250px;
  /* height: 119px; */
  padding-right: 20px;
}

.chat-msg {
  color: #444;
  padding: 8px 18px;
  line-height: 20px;
  font-size: 13px;
  // border-radius: 7px;
  display: inline-block;
  position: relative;
  background-color: #f2f6f8;
  border: 1px solid #dbe3e7;
  border-radius: 10px;
  max-width: 100%;
  width: 300px;
  text-align: left;
}

.upfate-form {
  .form-check {
    .form-check-label {
      padding-left: 5px;
      font-size: 13px;
    }
  }
}

.text-divider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-divider > span:first-child {
  width: 100%;
  height: 1px;
  flex: 1;
  /*background: #ebecf1;*/
  display: inline-block;
}

.custom-control-label {
  line-height: 26px;
}

label {
  font-size: 12px;
  font-weight: 600;
}

.text-divider > span:not(:first-child):not(:last-child) {
  padding: 0 20px;
  /*width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefefee;
  border-radius: 50%;
   border: 1px solid #e5e5e5;
}
.text-divider > span:last-child {
  width: 100%;
  height: 1px;
  flex: 1;
  /*background: #ebecf1;*/
  display: inline-block;
}

/*payment card*/
.card.payment-card {
  width: 500px;
  max-width: 100%;

  label {
    font-weight: 600;
    color: #303b93;
    flex: none;
  }

  .form-control {
    border: none;
    border-bottom: 1px solid #e1dede;
    border-radius: 0;
    padding: 0 40px 0 0;
  }
}

.payment-formicon {
  font-size: 23px;
  color: #5962a9;
  position: absolute;
  right: 12px;
  opacity: 0.5;
}

.card.success-box {
  background: #f0feea;
}

/*Utility style*/
.w-200px {
  width: 200px;
  min-width: 200px;
}

.fs-60 {
  font-size: 60px;
}

.fs-30 {
  font-size: 30px;
}

.fs-22 {
  font-size: 22px;
}

.fs-14 {
  font-size: 14px;
}

.fs-1rem {
  font-size: 1rem;
}

// .btn-icon {
//   padding: 6px 12px;
// }

.text-green {
  color: #4bb71b;
}

.fw-600 {
  font-weight: 500;
}

.fs-11px {
  font-size: 11px;
}

.fs-12px {
  font-size: 12px;
}

.fs-20px {
  font-size: 20px;
}

// .w-200px {
//   width: 200px;
//   min-width: 200px;
// }

.text-sm {
  font-size: 13px;
}

.h-40 {
  height: 40px;
}

.fs-18px {
  font-size: 20px;
}

.bg-light2 {
  background: #eee;
}

.cursor-pointer {
  cursor: pointer;
}

.light-bg2 {
  background: #eee;
}

.text-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.blue-bg {
  background-color: #303b93 !important;

  .card-title {
    color: #fff !important;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}

.copy-tool-section .custom-control {
  left: 5px;
  top: 2px;
  z-index: 0;
}

/*Success Message Style*/
.success-animation {
  margin: 10px auto 30px;
}

.downloadcsvbox {
  position: relative;
  top: 20px;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #f0feea;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.chat-section {
  padding: 15px 0 15px 15px;
  background: #eeeff7;
  border-radius: 4px;
  border: 1px solid #e6e6ed;
}

.chattypearea {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  resize: none;
  padding-right: 40px;
  height: 40px;
  width: calc(100% - 70px);
}

.c-pointer {
  cursor: pointer;
}

.send-msg {
  position: absolute;
  right: 0;
  top: 0;
}

.chatbox .ps__thumb-y {
  background: #d6d8e9;
}

.play-btn-none .pp-btn {
  display: none;
}

.browse-btn-area {
  background: #eee;
  min-height: 200px;
}

/*Tickit Page*/
.btn-icon {
  font-size: 1rem !important;
  color: #303b93 !important;
  cursor: pointer;
  padding: 6px 12px;
  width: auto !important;
  line-height: 24px;
  min-width: 40px;
}

#svgDataShow img {
  width: 100%;
  //height: 860px;
  height: 800px;
}

.text-note {
  border-left: 2px solid #eee;
  padding: 0 20px;
  /*  background: #f5f5f5;*/
}

.text-note p {
  font-size: 13px;
  color: #555;
}

/*Height*/
.croparea {
  height: 200px;
}

.ReactCrop__image {
  max-height: 250px !important;
}

/*Full page condition*/

.full-page .main-panel.expanded {
  width: 100%;
}

.full-page .navbar {
  display: none;
}

.full-page .container-fluid {
  margin-top: 0;
}

/*disable btn*/
.btn:disabled,
.btn[disabled],
.btn.disabled {
  cursor: not-allowed;
}

/*Ticket Details Modal*/
.tickit-details-table {
  tr {
    td {
      word-break: break-word;
      line-height: 16px;
      padding: 2px 8px !important;
      font-size: 13px;
    }

    th {
      white-space: nowrap;
      padding: 2px 8px !important;
      font-size: 13px;
    }
  }
}

.display-error h1 {
  font-size: 22px;
  position: relative;
  top: 150px;
}

.mt-3.mt-sm-0.row:not(:first-child) .col-sm-auto label {
  min-height: 0 !important;
  margin-bottom: 0;
  font-size: 0;
}

/*Footer*/
.footer {
  background: rgba(214, 216, 233, 1) !important;
  height: 40px !important;
  font-size: 13px;
}

/*Dropdown*/

.dropdowngroup {
  width: 100%;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;

  .navitem {
    list-style: none;
  }
}

.dropdown.show {
  .dropdowngroup {
    width: 100%;
    height: auto;
    background: #262f75;
    opacity: 1;
    transition: all 0.5s ease;
    display: block;
    padding: 5px 0;
  }
}

.dd-link {
  position: relative;
}

.dd-link:after {
  content: "";
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  position: absolute;
  top: 20px;
  right: 15px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: transform 0.5s;
}

.show.dropdown .dd-link:after {
  top: 20px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

/*========== Flex Height Structure ==========*/
.main-panel {
  display: flex;
  flex-direction: column;
  height: 100vh !important;

  .content {
    flex: 1;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}

a {
  text-decoration: none;
  color: #24a5ca !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.content > .container-fluid {
  height: 100%;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  padding: 0;
  border-radius: 10px;
}

/*========== Super Admin ==========*/
.ticket-setup-reason {
  padding-bottom: 20px;
  border-bottom: 2px solid #dfdfdf;
}

.MuiTableRow-root {
  height: auto !important;
}

.mt-fix-height {
  height: calc(100vh - 390px) !important;
}

div[class*="Component-horizontalScrollContainer"] div > div {
  height: calc(100vh - 444px);
}

/*========== Self Register ==========*/

.fs-16px {
  font-size: 16px !important;
}

.fs-17px {
  font-size: 17px !important;
}

.w-150px {
  width: 150px;
}

.h-150px {
  height: 150px;
}

// a {
//   color: #24a5ca !important;
// }

/*========== Flex Height Structure ==========*/
.card .rdt_Table {
  /*border:1px solid #d5d5d5;*/
  padding-top: 0;
  margin-top: 15px;
}

/*.card .rdt_TableHeadRow {
      background: #f3f3f3;
}*/

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

/* @media only screen and (min-width: 1025px) and (min-width: 1025px) {
 .instruction-box {
    flex: 0 0 20.333333%;
    max-width: 20.333333%;
    min-width: 330px;
}
}*/

@media only screen and (min-width: 1200px) {
  .instruction-box {
    flex: 0 0 20.333333%;
    max-width: 20.333333%;
    min-width: 330px;
  }

  .instruction-box .scanbarbox {
    flex: none;
    max-width: inherit;
    width: calc(100% - 330px);
  }
}

@media only screen and (max-width: 991px) {
  .ins-text {
    text-align: center !important;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  #fullTicketDetails .modal-content .modal-footer .btn {
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    font-size: 14px;
  }

  .chat-msg-scroll {
    /* height: 250px; */
    height: 170px !important;
    padding-right: 20px;
  }

  // Permit Register Page
  .instruction-box {
    border-right: none;
    border-bottom: 1px solid #dee2e6 !important;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }

  .border-right.instruction-box.col-lg-4.col-md-4 {
    max-width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 20px;
    background: #f8f8f8;
    padding: 10px;
    border: none !important;
  }

  .credit-container {
    max-width: 100%;

    .row {
      height: 260px;
      overflow: auto;
    }

    .card-header-tabs {
      .nav-link {
        font-size: 12px;
        font-weight: 600;
        padding: 0.5rem 0.5rem;
      }
    }

    .table {
      td,
      th {
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .chat-msg {
    width: 280px;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0;
    margin: 0;
    margin-left: 6px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0px;
  }

  a.text-dark.navbar-brand {
    font-size: 17px;
  }
}

/* Calender PLugins Add */

/* .weekpicker-box .outline {
    margin: 0.5rem;
} */

/*.font {
    font-size: 1rem;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}*/

.weekpicker-box .btn:not(:disabled) {
  cursor: pointer;
}

.weekpicker-box .btn {
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.weekpicker-box .primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.weekpicker-box .primary-box {
  border-color: #007bff;
  color: #007bff;
}

.weekpicker-box .primary-box tbody > tr:hover {
  background-color: #007bff;
  color: #fff;
}

.weekpicker-box .primary-box span:hover {
  background-color: #007bff;
  color: #fff;
}

.weekpicker-box .green {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.weekpicker-box .green-box {
  border-color: #28a745;
  color: #28a745;
}

.weekpicker-box .red {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.weekpicker-box .red-box {
  border-color: #dc3545;
  color: #dc3545;
}

.weekpicker-box .yellow {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.weekpicker-box .yellow-box {
  border-color: #ffc107;
  color: #ffc107;
}

.weekpicker-box .info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.weekpicker-box .info-box {
  border-color: #17a2b8;
}

.weekpicker-box .light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.weekpicker-box .light-box {
  border-color: #f8f9fa;
}

.weekpicker-box .dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.weekpicker-box .dark-box {
  border-color: #343a40;
}

.weekpicker-box .outline-primary {
  color: #007bff;
  background-color: rgb(237, 239, 245);
  background-image: none;
  border-color: #007bff;
}

.weekpicker-box .flex {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
  width: 100%;
}

.weekpicker-box .box {
  width: 277px;
  border-radius: 5px;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  min-width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1%);
  border: 1px solid #f9f9f9 !important;
}

.weekpicker-box .display {
  display: none;
}

.weekpicker-box .navbar {
  width: 274px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  height: auto !important;
  min-height: auto;
  padding: 0 10px;
}

.weekpicker-box .item {
  padding: 6px;
  cursor: pointer;
}

.weekpicker-box .arrow {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
}

.weekpicker-box .icon {
  width: 1.0634765625em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
  margin: 0 4px;
}

.weekpicker-box .row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 274px;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
}

.weekpicker-box .row td {
  padding: 5px 5px 5px 5px;
  font-size: 14px;
}

.weekpicker-box .highlighted {
  color: #fff;
  background-color: #007bff;
}

.weekpicker-box .unset-button {
  all: unset;
}

.weekpicker-box .week-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin: 8px;
}

.weekpicker-box thead .row td.font {
  font-weight: 600;
  text-transform: capitalize;
}

.card-header-tabs {
  margin: 0;
}

/* Add/Edit User Types */

.usertype-table-header.user-table-row {
  background: #f5f5f5;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  // width: 2960px;
}

.usertype-table-header > .row {
  background: none;
}

.usertype-table-body > .col {
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.user-table-row > .col {
  padding-left: 7px;
  padding-right: 7px;
}

.datatable-scroll {
  overflow: auto;
  height: calc(100vh - 271px);
}

.fs-14px {
  font-size: 14px;
}

.fw-500 {
  font-weight: 500;
}

.usertype-table-body .fa-plus-circle:before {
  content: "\f055";
  position: relative;
  top: 5px;
  font-size: 16px;
  cursor: pointer;
}

.small-table {
  max-width: 100%;
  border: 1px solid #e1e1e6 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.small-table tr th {
  color: #333 !important;
  font-weight: 600 !important;
  font-size: 10px;
}

.small-table.table > thead > tr > th,
.small-table tr td {
  border: 1px solid #d3d3d3;
  font-size: 11px;
  text-align: center !important;
  padding: 5px !important;
}

.small-table.table > thead > tr > th {
  font-size: 11px;
  font-weight: 700 !important;
  color: #000 !important;
}

.min-w-340px {
  min-width: 390px;
  align-items: center;
  display: flex;
}

.upfate-form .form-control.w-200px {
  padding: 6px 10px;
  font-size: 12px;
  height: auto;
}

// .citation-list-table > div {
//   /* max-height: initial; */
// }

.main-panel > .content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

// Parking permit
span.field-with-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  pointer-events: none;
  opacity: 0.5;
}

.icon-grps.position-relative input {
  padding-right: 40px;
}

// Superadmin

// All Properties
.citation-list-table.list-of-ravenue-table.sa-all-property-table
  > div:first-child {
  min-height: calc(100vh - 280px);
  height: auto;
}

// People Search
.people-search-table.sa-people-search-table > div:first-child {
  min-height: calc(100vh - 275px) !important;
  max-height: calc(100vh - 275px) !important;
}

@media (min-width: 991px) and (max-width: 1090px) {
  body .people-search-table.sa-people-search-table > div:first-child {
    min-height: calc(100vh - 308px) !important;
    max-height: calc(100vh - 308px) !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  body .people-search-table.sa-people-search-table > div:first-child {
    min-height: calc(100vh - 308px) !important;
    max-height: calc(100vh - 308px) !important;
  }
}

@media (max-width: 767px) {
  body .people-search-table.sa-people-search-table > div:first-child {
    min-height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .citation-list-table.list-of-ravenue-table.sa-all-property-table
    > div:first-child {
    min-height: calc(100vh - 330px) !important;
    height: auto;
  }
}

@media (max-width: 420px) {
  body .people-search-table.sa-people-search-table > div:first-child {
    min-height: calc(100vh - 360px) !important;
    max-height: calc(100vh - 360px) !important;
  }
}

img.pciLogo {
  width: 80px;
}

img.sslLogo {
  width: 40px;
}
