.view-all-permit-table .rdt_TableBody {
  min-height: calc(100vh - 376px);
  max-height: calc(100vh - 376px);
}
// .view-all-permit-table .rdt_TableBody,
// .eaqGUG {
//   // overflow-x: auto !important;
//   // overflow-y: auto !important;
// }

@media (max-width: 767px) {
  .view-all-permit-table .rdt_TableBody {
    min-height: calc(100vh - 468px);
    max-height: calc(100vh - 468px);
  }
  .mo-none {
    display: none;
  }
}
