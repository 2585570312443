.dashboard-container {
  .card {
    .card-title {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.ct-chart-bar .ct-label.ct-horizontal.ct-end,
.ct-label.ct-vertical.ct-start {
  color: black;
  font-size: 14px !important;
}
@media (max-width: 786px) {
  .ct-chart-bar .ct-label.ct-horizontal.ct-end,
  .ct-label.ct-vertical.ct-start {
    font-size: 12px !important;
  }
}
@media (max-width: 576px) {
  .days-chart {
    overflow: auto;
    max-width: 650px;
  }
  .card .ct-chart {
    width: 650px;
  }
}
