.scan-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scanned-image img {
  height: 200px;
}

.scanner-corners {
  position: relative;
  padding: 12px;

  section {
    height: 200px !important;

    div {
      padding-top: 60% !important;
    }
  }

  video {
    height: 190px !important;
    width: 100% !important;
  }

  &.scanner-corners-photo-glass {
    video {
      height: 190px !important;
    }
  }

  .top,
  .bottom {
    position: absolute;
    width: 60px;
    height: 60px;
    pointer-events: none;
  }

  .top {
    top: 0;
    border-top: 5px solid #303b93;
  }

  .bottom {
    bottom: 0;
    border-bottom: 5px solid #303b93;
  }

  .left {
    left: 0;
    border-left: 5px solid #303b93;
  }

  .right {
    right: 0;
    border-right: 5px solid #303b93;
  }
}
.qr-code-text {
  text-align: center;
  font-size: 14px;
}

.qr-code-text p,
.scan-photo-field p {
  font-size: 14px;
}

.capture-photo-button {
  color: #303b93;
  background-color: transparent;
  font-weight: 400;
  opacity: 0.8;
  border-radius: 3px;
  padding: 6px 16px;
  border: 2px solid #303b93;
  text-align: center;
}
button.scan-plate-button {
  padding: 6px 16px;
}

// photo-verify css
@media (min-width: 576px) {
  .scanner-modal .modal-dialog {
    width: 350px;
  }
}

@media (max-width: 768px) {
  .scanner-card.card {
    .card-title {
      font-size: 16px;
    }

    select {
      font-size: 12px;
    }

    button {
      font-size: 12px;
    }
  }
}
