.rdt_TableCell img.img-fluid {
  height: 30px;
  width: 65px;
}

.lpr-report-table .rdt_TableHead + div {
  min-height: calc(100vh - 283px) !important;
}

/* photo-verify-details-table */
.photo-verify-details-table .rdt_TableBody {
  min-height: calc(100vh - 486px);
  max-height: calc(100vh - 486px);
}

.photo-verify-details-table .rdt_TableBody,
.eaqGUG {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

input.photo-verify-form-control,
select.photo-verify-form-control {
  height: 44px;
}

@media (max-width: 767px) {
  .photo-verify-details-table .rdt_TableBody {
    min-height: calc(100vh - 568px);
    max-height: calc(100vh - 568px);
  }

  .nav-tabs .nav-link {
    font-size: 13px;
    padding: 10px 10px;
  }

  input.photo-verify-form-control,
  select.photo-verify-form-control {
    height: 38px;
  }
}

/* citation-issued-table */

.citation-issued-table .rdt_TableBody {
  min-height: calc(100vh - 680px);
  max-height: calc(100vh - 680px);
}

.citation-issued-table .rdt_TableBody,
.eaqGUG {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

@media (max-width: 767px) {
  .citation-issued-table .rdt_TableBody {
    min-height: calc(100vh - 568px);
    max-height: calc(100vh - 568px);
  }

  .nav-tabs .nav-link {
    font-size: 13px;
    padding: 10px 10px;
  }
}

/* citation-paid-table */

.citation-paid-table .rdt_Table {
  margin-top: 0;
}

.citation-paid-table .rdt_TableBody {
  min-height: inherit !important;
  max-height: inherit !important;
}

.eaqGUG {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

@media (max-width: 767px) {
  .sidebarFilter .card-footer .btn-sm {
    font-size: 12px;
    padding: 1px 20px;
  }

  .search-mb-0 .active-permit-header {
    align-items: center;
    flex-direction: inherit;
  }
}

/* report-citation-box */

.report-citation-box .rdt_Table {
  margin-top: 0;
}

.report-citation-box .rdt_TableBody {
  min-height: calc(100vh - 558px);
  max-height: calc(100vh - 558px);
}

.report-citation-box .rdt_TableBody,
.eaqGUG {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  .zoom-icon-btn {
    display: none !important;
  }

  .citation-list-table.view-all-permit-table .rdt_TableBody {
    min-height: calc(100vh - 340px);
    /* overflow: hidden !important; */
  }
}

@media (max-width: 991px) {
  body {
    overflow-x: hidden;
  }

  body.foc-open:after {
    z-index: -1 !important;
  }

  body.screen-zoomin-out:after {
    display: none;
  }

  .lpr-report-table .rdt_TableHead + div {
    min-height: calc(100vh - 300px) !important;
  }

  /* .filter-button.d-flex.flex-wrap {
    margin-left: 10px;
} */

  .table-responsive .rdt_TableBody {
    /*  min-height: inherit !important; */
    min-width: 500px !important;
    overflow: scroll !important;
  }
}

@media (max-width: 767px) {
  .report-citation-box .rdt_TableBody {
    min-height: calc(100vh - 330px);
    max-height: calc(100vh - 330px);
  }

  .report-citation-box .rdt_TableHead + div {
    min-height: calc(100vh - 280px) !important;
    max-height: calc(100vh - 280px) !important;
  }

  .mo-none {
    display: none !important;
  }

  .lpr-report-table .rdt_TableHead + div {
    min-height: calc(100vh - 320px) !important;
  }

  .sidebarFilter .card .card-body {
    max-height: calc(100vh - 188px) !important;
  }

  .header-search-button .form-control {
    width: 180px !important;
  }

  .foc-open .ps__rail-y {
    position: relative;
    overflow: hidden !important;
  }

  .foc-open .sidebarFilter {
    width: 97%;
  }

  .filter-btn-bottom .search-container {
    position: relative;
    margin-top: 10px;
  }

  .filter-button {
    justify-content: flex-start !important;
  }

  .citation-list-table.list-of-ravenue-table > div:first-child {
    min-height: calc(100vh - 350px) !important;
  }

  .d-flex .form-check .form-check-label {
    padding-left: 5px;
    margin-right: -1px;
  }

  .foc-open .main-panel .navbar {
    display: none;
  }
}

body.screen-zoomin-out .rdt_TableBody {
  min-height: calc(100vh - 195px) !important;
  max-height: calc(100vh - 195px) !important;
}

/* Manage Unit Page */
/* Page URL: Residents.js */
.page-no-fliter .filter-icon-btn {
  display: none !important;
}

/* view-all-permit-table */
.view-all-permit-table {
  min-height: calc(100vh - 290px);
}

/* Current Visitors, Manage Unit Full screen */

body.screen-zoomin-out
  .citation-list-table.current-visitor-table
  > div:first-child {
  max-height: calc(100vh - 164px);
}

body.screen-zoomin-out
  .citation-list-table.manage-unit-table
  > div:first-child {
  min-height: calc(100vh - 164px);
  max-height: calc(100vh - 164px);
}

/* View all permit*/
body.screen-zoomin-out
  .citation-list-table.view-all-permit-table
  .rdt_TableBody,
body.screen-zoomin-out
  .citation-list-table.verify-audit-table
  > div:first-child,
body.screen-zoomin-out .citation-list-table.manage-unit-table .rdt_TableBody {
  min-height: initial !important;
  max-height: inherit !important;
}

body.screen-zoomin-out
  .citation-list-table.view-all-permit-table
  > div:first-child {
  /* min-height: calc(100vh - 300px); */
  /* overflow: hidden !important; */
  max-height: calc(100vh - 165px);
}

/* verify-audit-table */
body.screen-zoomin-out
  .citation-list-table.verify-audit-table
  > div:first-child {
  max-height: calc(100vh - 164px);
}

body.screen-zoomin-out .citation-paid-table .rdt_TableBody {
  min-height: initial !important;
  max-height: initial !important;
}

.citation-list-table.list-of-ravenue-table .rdt_TableBody {
  min-height: initial !important;
  max-height: initial !important;
}

.citation-list-table.list-of-ravenue-table > div:first-child {
  min-height: calc(100vh - 297px);
}

body.screen-zoomin-out
  .citation-list-table.list-of-ravenue-table
  > div:first-child {
  min-height: calc(100vh - 160px);
}

.citation-list-table > div:first-child {
  -webkit-overflow-scrolling: auto;
  -webkit-overflow-scrolling: touch;
  max-height: initial !important;
}

/* Super admin : People Search */
.people-search-table > div:first-child {
  max-height: calc(100vh - 288px);
  min-height: calc(100vh - 288px);
}

.custom-input-search {
  min-width: 181px;
  border-radius: 3px 0 0 3px !important;
  margin-right: -1px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .people-search-table > div:first-child {
    max-height: calc(100vh - 520px);
    min-height: calc(100vh - 520px);
  }
}

@media (max-width: 767px) {
  .people-search-table > div:first-child {
    max-height: calc(100vh - 408px);
    min-height: calc(100vh - 408px);
  }
}

.activity-log-table .rdt_TableBody {
  min-height: calc(100vh - 300px) !important;
}

.activity-log-table {
  border: 1px solid #e1e1e6 !important;
  box-shadow: "0 0 10px rgb(0 0 0 / 10%)";
}

.change-credit-card-box {
  border: 1px solid #dbd8d8;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 5px;

  .form-control {
    padding-right: 50px;
  }

  .payment-formicon {
    font-size: 19px;
    right: 16px;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
  }

  .react-datepicker__navigation {
    top: 5px;
  }

  .react-datepicker__navigation-icon::before {
    top: 16px;
  }

  .react-datepicker__month .react-datepicker__month-text {
    margin: 0;
    width: 100%;
    padding: 0 10px;
  }
}
