.manageunit-table .rdt_TableBody {
  min-height: calc(100vh - 255px);
  max-height: calc(100vh - 255px);
}
.manageunit-table .rdt_TableBody,
.eaqGUG {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

@media (max-width: 767px) {
  .manageunit-table .rdt_TableBody {
    min-height: calc(100vh - 508px);
    max-height: calc(100vh - 508px);
  }
}
