.image-section img {
  max-width: 100%;
  max-height: 400px;
}
.guest-section {
  h4 {
    font-weight: 500;
    margin: 15px 0 15px 0;
  }
}
// .guest-entry {
//   button {
//     font-size: 14px !important;
//     border-radius: 5px;
//     padding: 5px 15px;
//     background: transparent;
//   }
//   h5 {
//     font-weight: 600;
//     width: 70px;
//   }
// }
.min-width-modal {
  min-width: 160px;
  max-width: 100px;
}

.liveFeedIcon {
  top: -3px;
  position: relative;
}

.customliveButton {
  margin-bottom: -3px;
  font-size: 12px;
  // margin-left: -23px !important;
}

.startButton {
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin-right: 12px;
  cursor: pointer;
}

.startButton span {
  font-size: 11px;
  color: #3643a9;
}

.ActionButton {
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  padding-right: 15px;
  border-left: 1px solid #eee;
  padding-left: 15px;
  cursor: pointer;
  // &:focus {
  //   background-color: #eee !important;
  // }
}

.ActionButton:active {
  background-color: #eee !important;
}

.ActionButton span {
  font-size: 11px;
  color: #3643a9;
}
.cameraStream {
  min-height: auto;
  position: relative;
}
.AllActionGroup .align-items-center {
  margin-top: 0;
}

.play-pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-pause-btn .btn {
  border: none;
  padding: 0;
  display: none;
}

.cameraStream:hover .btn {
  display: block;
}

.pp-btns {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 44px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 21px 4px 22px 30px;
}

.playpause {
  display: block;
  box-sizing: border-box;
  width: 0;
  height: 40px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: double;
  border-width: 0px 0 0px 33px;
}

@media only screen and (max-width: 1220px) {
  .ActionButton span {
    display: block;
  }

  .AllActionGroupInner.currentActionbox span span {
    display: inline-block;
    min-width: 100px;
  }
}

@media only screen and (min-width: 1200px) {
  .col-xxl-auto {
    min-width: 440px;
    max-width: auto;
    flex: 0;
  }
  .guest-section .min-width-modal {
    min-width: 130px;
    max-width: 130px;
  }
}

@media only screen and (max-width: 991px) {
  .ActionButton {
    /*  padding: 0 10px; */
  }
  .AllActionGroup {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
  }
  .AllActionGroup .ml-3 {
    margin-left: 0 !important;
  }
  .guest-section .AllActionGroupInner:first-child {
    /* margin-left: 0 !important;
    padding-left: 0;
    border-left: none; */
  }
  .guest-section.d-flex.align-items-center.mb-2 {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .guest-section .min-width-modal {
    margin-top: 10px;
    margin-bottom: 10px !important;
  }

  .flex-column-sm {
    flex-wrap: wrap;
  }
  .AllActionGroup .flex-column-sm .ActionButton {
    padding: 0 8px !important;
    margin: 15px 0 10px 0 !important;
  }
  .AllActionGroup .flex-column-sm .currentActionbox {
    margin-top: 0 !important;
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  body .cameraStream {
    min-height: 200px;
  }
  body .AllActionGroup {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .AllActionGroupInner {
    padding-right: 0 !important;
    align-items: flex-start !important;
  }
  .ActionButton span {
    font-size: 11px;
    color: #3643a9;
    line-height: 13px;
    margin-top: 1px;
    position: relative;
    top: 1px;
  }
  .AllActionGroup .align-items-center {
    align-items: flex-start !important;
    /* margin-top: 10px; */
  }
  .guest-section .min-width-modal {
    min-width: 150px;
    max-width: 100px;
  }

  .AllActionGroup {
    margin-top: 10px;
  }
  .guest-section .AllActionGroup {
    margin-top: 0;
  }

  .AllActionGroupInner:first-child {
    margin-left: 0 !important;
  }
  .AllActionGroupInner.currentActionbox {
    margin-left: 0 !important;
    padding-left: 8px !important;
    /* border-left: 0 !important; */
  }
}
