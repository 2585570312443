/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.fixed-plugin .button-container .github-btn {
  display: inline-block;
  font-size: 14px;
}

/*.fixed-plugin .button-container .github-btn .gh-btn,
.fixed-plugin .button-container .github-btn .gh-count{
    padding: 5px 7px 5px 7px;
}*/
.fixed-plugin .SocialMediaShareButton {
  display: inline-block;
  margin: 0 2px;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.selfRegisterHeader {
  cursor: pointer;
  /* display: inline-block; */
  position: absolute;
  height: 36px;
  margin: 8px 0px 0px 14px;
  text-align: left;
  align-items: flex-start;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}

.all-icons input {
  border: 0;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

.main-panel>.content {
  background: rgba(203, 203, 210, 0.15);
  padding: 20px 5px 60px 5px;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.sidebar .nav>li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav>li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.fixed-plugin {
  position: absolute;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 10px;
  padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
  right: 10px;
  margin-left: auto;
  left: auto;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.footer-dropdown {
  top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
  top: 300px !important;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  max-height: 175px;
  margin: 0 auto;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-white {
  background-color: #ffffff;
}

.fixed-plugin .badge-black {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-azure {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-green {
  background-color: #87cb16;
}

.fixed-plugin .badge-orange {
  background-color: #ffa534;
}

.fixed-plugin .badge-purple {
  background-color: #9368e9;
}

.fixed-plugin .badge-red {
  background-color: #fb404b;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 5px 2px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  text-align: center;
}

.fixed-plugin .pro-title {
  margin: 10px 0 5px 0;
  text-align: center;
}

.fixed-plugin #sharrreTitle {
  text-align: center;
  padding: 10px 0;
  height: 50px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
}

.fixed-plugin .adjustments-line .switch {
  float: right;
}

.fixed-plugin .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  opacity: 1;
  cursor: pointer;
  max-height: 86px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.switch-trigger:hover,
.fixed-plugin .dropdown-menu>li>a.switch-trigger:focus {
  background-color: transparent;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown-menu>li>a img {
  margin-top: auto;
}

.fixed-plugin .btn-social {
  display: block;
  width: 48%;
  float: left;
  font-weight: 600;
}

.fixed-plugin .btn-social i {
  margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
  margin-right: 2%;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  opacity: 1;
}

/*footer fix*/
.fixed-bottom {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
}

.full-page {
  padding-bottom: 40px;
}

.full-page .fixed-bottom {
  position: fixed;
  width: 100%;
  left: 0;
  top: auto;
  bottom: 0;
}

.footer-full-page .fixed-bottom {
  width: 100%;
  left: 0;
}

/*height-100% - footer fix*/
.scrollbar-container+.fixed-bottom {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
}

@media (min-width: 992px) {
  .fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 27px;
    opacity: 0;

    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown.open .dropdown-menu {
    opacity: 1;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }

  .fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -16px;
  }

  .fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #ffffff;
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -15px;
  }

  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  .fixed-plugin {
    top: 300px;
  }
}

@media (max-width: 991px) {
  .fixed-plugin .dropdown-menu {
    right: 60px;
    width: 220px;
  }

  .fixed-plugin .dropdown-menu li {
    width: 50%;
  }

  .fixed-plugin li.adjustments-line,
  .fixed-plugin li.header-title,
  .fixed-plugin li.button-container {
    width: 100%;
    height: 55px;
    min-height: inherit;
  }

  .fixed-plugin .adjustments-line .switch {
    float: right;
    margin: 0 0px;
  }

  .fixed-plugin li.header-title {
    height: 40px;
  }

  .fixed-plugin .dropdown .dropdown-menu {
    top: -170px;
  }

  .footer {
    flex-direction: inherit;
  }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}

.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}

.btn-social.btn-round {
  padding: 9px 10px;
}

.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}

.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open>.btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}

.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}

.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}

.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open>.btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}

.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open>.btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}

.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}

.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}

.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open>.btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

body .all-icons [class*="pe-"] {
  font-size: 40px;
}

body .all-icons input {
  border: 0;
}

body .all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

body .all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

body .places-buttons .btn {
  margin-bottom: 30px;
}

body .sidebar .nav>li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

body .sidebar .nav>li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

body .table-upgrade td:nth-child(2),
body .table-upgrade td:nth-child(3) {
  text-align: center;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease; */
  transition: all 0.25s ease;
  outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1fc6ea;
}

.github-btn {
  font: bold 11px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}

.gh-btn,
.gh-count,
.gh-ico {
  float: left;
}

.gh-btn,
.gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}

.gh-btn {
  background-color: #eee;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #fcfcfc),
      color-stop(100%, #eee));
  background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}

.gh-btn:hover,
.gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #eee),
      color-stop(100%, #ddd));
  background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}

.gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.gh-count {
  position: relative;
  display: none;
  /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}

.gh-count:hover,
.gh-count:focus {
  color: #4183c4;
}

.gh-count:before,
.gh-count:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}

.gh-count:after {
  top: 50%;
  left: -4px;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d4d4d4;
}

.github-btn-large {
  height: 30px;
}

.github-btn-large .gh-btn,
.github-btn-large .gh-count {
  padding: 3px 10px 3px 8px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
}

.github-btn-large .gh-ico {
  width: 20px;
  height: 20px;
}

.github-btn-large .gh-count {
  margin-left: 6px;
}

.github-btn-large .gh-count:before {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}

.github-btn-large .gh-count:after {
  left: -6px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
}

.typography-line span {
  bottom: 10px;
  color: #9a9a9a;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  left: 5px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.typography-line {
  padding-left: 15%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.btn-toggle {
  margin: 0 !important;
}

.help-block {
  color: #dc3545;
  font-size: 13px;
  margin-top: 3px;
}

.success-block {
  color: #35dc43;
  font-size: 14px;
  margin-top: 5px;
}

.ct-label {
  font-size: 14px;
}

.font-14 {
  font-size: 14px;
}

.asterisk {
  color: #ff4a55;
  font-size: 14px;
  margin-left: 5px;
}

.h-40 {
  height: 40px;
  /* line-height: 38px; */
  padding-top: 0;
  padding-bottom: 0;
}

.react-datetime-picker__wrapper {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 8px 12px;
  height: 40px;
  color: #495057;
}

.react-datetime-picker__wrapper input {
  color: #495057;
}

.toast {
  display: flex;
  align-items: center;
  position: fixed;
  background: #000;
  color: #fff;
  z-index: 9;
  padding: 2px 50px 2px 10px;
  border-radius: 50px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 65px;
}

.toast-success {
  background: #87cb16;
}

.toast-error {
  background: #dc3545;
}

.toast-header {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  padding: 0;
}

.toast-header .close {
  margin: 0 !important;
  padding: 0;
  display: flex;
  align-items: center;
  text-shadow: none;
  color: #fff;
  opacity: 1;
}

.btn-close-nav {
  margin-left: auto;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 20px;
}

.manageunit-table .rdt_TableBody {
  overflow-x: hidden;
}

.rdt_TableBody {
  /* min-height: calc(100vh - 380px);*/
  max-height: calc(100vh - 380px);
}

/* .lpr-report-table .rdt_TableBody {
  max-height: inherit !important;
  max-height: inherit;
} */

.rdt_Table .card {
  margin-bottom: 0;
}

.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 10px 0 10px;
  font-size: 12px;
}

.custom-pagination>div {
  margin-right: 5px;
}

.custom-pagination label {
  margin: 0 5px !important;
}

.custom-pagination .fas {
  color: rgba(0, 0, 0, 0.54);
}

.header-search-button {
  display: flex;
}

.header-search-button .search-btn {
  margin-left: 10px;
}

.custom-btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.custom-pagination select {
  font-size: 12px;
}

/*AR479*/
.uer-list .MuiTableBody-root .MuiTableRow-root {
  vertical-align: top;
}

.uer-list .MuiTableBody-root .MuiTableRow-root.Mui-selected {
  vertical-align: middle;
}

.setting-screen .card {
  height: 100%;
}

.close-btn:active {
  background-color: transparent !important;
  color: inherit !important;
}

.close-btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn.btn-blue-bg {
  background-color: #303b93 !important;
  color: #fff !important;
}

.btn.btn-blue-bg .MuiSvgIcon-root {
  width: 0.7em;
  height: 0.7em;
}

.btn-blue-bg:hover,
.btn-blue-bg:focus,
.btn-blue-bg:active {
  background-color: #323fab;
  color: #fff;
}

.btn-cancel {
  background-color: #ff4a55;
  color: #fff;
}

.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active {
  background-color: #ff4a55;
  color: #fff;
}

.btn.btn-fill.btn-toggle-new,
.btn.btn-fill.btn-toggle-new:active,
.btn.btn-fill.btn-toggle-new:hover,
.btn.btn-fill.btn-toggle-new:focus {
  background: transparent;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.btn-toggle-new img {
  width: 18px;
}

.btn {
  padding: 6px 12px;
}

.sidebar-background {
  background: #303b93;
}

.setting-radio .form-check-label {
  padding-left: 10px;
}

.top-header--title:hover {
  text-decoration: underline;
}

.rdt_TableCol_Sortable {
  font-weight: 700;
}

.MuiTable-root .MuiTableHead-root .MuiTableCell-head {
  font-weight: 700;
}

.MuiPaper-root .MuiToolbar-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 10px;
}

.rdt_Table {
  /*border-top: 1px solid rgba(0, 0, 0, 0.125);*/
  padding-top: 10px;
  margin-top: 10px;
}

.bottom-footer-btn {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.bottom-footer-btn.right-side {
  justify-content: flex-end;
}

.scanned-image {
  margin-bottom: 10px;
  text-align: center;
}

.scanned-image.top-space {
  margin-bottom: 0;
  text-align: center;
  margin-top: 30px;
}

.scanned-image img {
  max-height: 300px;
  object-fit: contain;
  max-width: 100%;
}

.btn-bottom {
  position: absolute;
  bottom: 6px;
}

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
  width: 698px;
  max-height: 300px;
}

.loader {
  width: 698px;
  max-height: 300px;
}

.check-plate-input.btn-primary.disabled,
.check-plate-input.btn-primary:disabled {
  color: inherit;
  cursor: pointer;
}

.check-plate-input.disabled .btn-primary:hover {
  opacity: 0.5;
}

.send-mail-form .form-group .col-item:last-child {
  padding: 6px;
}

.send-mail-form .photo-choose .photo-choose-items {
  margin-right: 20px;
  position: relative;
  width: auto;
  min-width: 140px;
  padding-right: 0;
  margin-bottom: 15px;
  display: inline-flex;
  flex-wrap: wrap;
}

.send-mail-form .photo-choose img {
  object-fit: fill;
  height: auto;
  min-height: 140px;
  max-height: 140px;
  min-width: 140px;
}

.send-mail-form .photo-choose .photo-choose-items .btn-danger {
  position: absolute;
  right: 2px;
  top: 6px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  color: #fff;
  border: none;
  transition: all 0.5s ease 0s;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-mail-form .photo-choose .photo-choose-items .btn-danger:hover {
  opacity: 1;
  transition: all 0.5s ease 0s;
}

.send-mail-form .input-file-upload-btn {
  position: relative;
  min-height: 140px;
}

.send-mail-form .input-file-upload-btn label {
  position: absolute;
  bottom: -4px;
  left: 0;
  z-index: 22;
  cursor: pointer;
}

.send-mail-form .input-file-upload-btn label i {
  font-size: 30px;
}

.send-mail-form #file-input-choose {
  display: none;
}

.send-mail-form .icon-choose {
  height: 30px;
  width: 30px;
}

.react-html5-camera-photo video {
  width: 100% !important;
}

.font-size-25 {
  font-size: 25px;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .responsive-mb-3 {
    margin-bottom: 15px;
  }

  .footer {
    font-size: 10px;
  }

  .footer .footer-link a {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
  }

  .main-panel>.content {
    padding: 20px 5px 100px 5px;
  }

  .toast {
    width: 90%;
    flex-basis: 353px;
    max-width: 353px;
  }
}

/*
.photo-choose-items {
   width: 140px;
} */

.upload-image-button {
  display: inline-block;
}

.icon-lpr-report:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/new-lpr-report.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-photo-verify:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/menu-photoverify.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-live-Feed:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/svg/live_feed_icons/live-view-menu.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-smart-gate:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/svg/smart-gate.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-plate-audit:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/menu-plateaudit.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-lot-sharing:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/svg/lot-share.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-self-register:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/svg/menu-add-self-permit.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-quick-permit:before {
  content: "";
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(../../assets/img/svg/menu-add-quickpermit.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-add-visitor:before {
  content: "";
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(../../assets/img/svg/menu-add-visitor.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-current-visitor:before {
  content: "";
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(../../assets/img/svg/menu-current-visitor.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-ticket-list:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/gavel.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-occupancy:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/cars.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* audio player css */

._1ceqH ._1lB9c {
  display: none;
}

._1ceqH ._dt3-T {
  background: transparent;
  min-height: auto;
}

._1Pz2d ._37kfa {
  display: none;
}

._1ceqH ._f2DT8 * {
  color: rgb(22, 11, 11);
  font-size: 20px;
  margin-top: 20px;
}

._eV_dK {
  display: none;
}

._1ceqH ._1Yplu ._1Pz2d {
  display: none !important;
}

._1ceqH ._1Yplu ._2gd2_ {
  display: block !important;
  float: right !important;
}

._1ceqH ._1Yplu {
  margin: unset;
}

._f2DT8 {
  float: right !important;
}

/* ticket-setup-css */
/* .ticket-setup-reason{
  width: ;
} */
.audio-react-recorder__canvas {
  width: 100px !important;
  height: 20% !important;
}

/*Setting Page*/
.custom-browse-btn {
  color: #ffffff;
  background-color: #24a5ca;
  width: 140px;
  border-radius: 4px;
  padding: 0 5px;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.custom-browse-btn input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.upload-text {
  line-height: 40px;
  text-align: center;
}

.image-preview-box {
  min-width: 100px;
  min-height: 56px;
  max-width: 100px;
  max-height: 56px;
  background-color: #f3f3f3;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview-box .image-preview {
  max-width: 100%;
}

.import-units table th {
  font-weight: 600 !important;
  color: #000 !important;
}

.import-units table th,
.import-units table td {
  font-size: 12px;
  text-align: left;
}

.importResponseFailed {
  color: red;
  font-weight: 500;
  /* display: flex; */
  display: table-cell;
  align-items: center;
  white-space: nowrap;
}

.importResponseFailed>div {
  display: flex;
}

.table-cell {
  white-space: nowrap;
  text-align: center;
}

.curserPointer {
  cursor: pointer;
  color: #3b5998 !important;
}

.snowFlake {
  cursor: pointer;
  color: #d6d8e9 !important;
}

.snowFlake:hover {
  cursor: pointer;
  color: #16f029 !important;
}

.Pointer {
  cursor: pointer;
}

.contactText {
  display: flex;
  font-size: 15px;
}

.mail {
  font-size: 17;
  margin: 2px 5px 0px 5px;
}

.align-center {
  align-items: center;
}

.d-flex .form-check .form-check-label {
  padding-left: 5px;
  margin-right: 20px;
}

/*Add visitor table scroll */
.scrollTable {
  max-height: 300px;
  overflow: auto;
}

.margin-table {
  margin: 10px;
}

.disabled-field,
.disabled-field span {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}

.a-tag,
.a-tag:hover {
  color: white;
  text-decoration: none;
}

.exportButton {
  margin-top: -50px;
  position: absolute;
  z-index: 1;
}

.without-sidebar-full-page .sidebar,
.without-sidebar-full-page .navbar,
.without-sidebar-full-page .fixed-bottom {
  display: none;
}

.without-sidebar-full-page .main-panel {
  width: 100%;
}

p:focus-visible,
a:focus-visible {
  outline: none !important;
}

/* button:focus {
  outline: 2px solid #303b93 !important;
  box-shadow: 1px 0 4px 0 #000000 !important;
}

#outer-circle:focus {
  outline: 2px solid #303b93 !important;
  box-shadow: 1px 0 4px 0 #000000 !important;
}

.text-nowrap:focus {
  outline: 2px solid #303b93 !important;
  box-shadow: 1px 0 4px 0 #000000 !important;
}

.capture-photo-button:focus {
  outline: 2px solid #303b93 !important;
  box-shadow: 1px 0 4px 0 #000000 !important;
} */

.SRLStage .css-1n6vy4l,
.SRLStage .css-h9a1eb {
  display: block !important;
}

.green-entry {
  color: #16c60c;
}

.red-exit {
  color: red;
}

/* lot sharing page css */

.lot-sharing-left-column.col {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  /* flex: 0 0 300px; */
  min-width: 300px;
}

.lot-sharing-left-column p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* width: 173px; */
  flex: 0 0 100px;
}

.usertype-table-header {
  align-items: center;
  /* justify-content: center */
  /* padding: 8px; */
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
  /* min-width: 1050px; */
  /* overflow: hidden; */
  /* border-bottom: 1px solid #c8c8c8; */
}

input.user-type-input {
  text-align: center;
}

.margin-table.usertype-table-body.user-table-row.row {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.usertypeHeader {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 0 0 100px;
  padding: 8px;
  position: relative;
  z-index: 1;
}

.ac-lots.col {
  max-width: 300px;
  /* flex: 0 0 300px; */
  min-width: 300px;
  padding-left: 20px;
}

.usertype-table-header.lotSharing-header .cl-al-column.col {
  max-width: 300px;
}

.lot-sharing-rows .col {
  display: flex;
  align-items: center;
}

.lot-sharing-rows:last-child {
  border: none;
}

.most-left-column {
  flex: 0 0 100px;
}

.user-table-row>.col {
  flex: 0 0 100px;
}

.usertype-table-header.lotSharing-header {
  background: transparent;
  justify-content: unset;
  border-bottom: 1px solid #c8c8c8;
}

.lot-sharing-rows {
  border-bottom: 1px solid #8080806e;
  padding: 8px;
  width: 100%;
  /* min-width: 1050px; */
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 45px;
}

.lot-sharing-column.col p {
  width: 100px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.lot-sharing-rows.row .checkbox-column:after {
  content: "";
  position: absolute;
  width: 100%;
  background: #c8c8c8;
  height: 1px;
  bottom: -1px;
  right: 0;
  left: 0;
}

.usertype-table-header .lot-sharing-column:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  left: 0;
  top: 0;
  z-index: -1;
}

.usertypeHeader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  left: 0;
  top: 0;
  z-index: -1;
}

.lot-sharing-column.col {
  padding: 8px;
  position: relative;
  z-index: 1;
}

.checkbox-column.col,
.lot-sharing-column.col {
  display: flex;
  justify-content: center;
  max-width: 100px;
  flex: 0 0 100px;
}

.datatable-scroll.lot-sharing-table-scroll {
  max-height: calc(100vh - 272px);
  height: auto;
}

/* For permit detail view */
.expandedView {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
}

.react-pdf__Page__textContent {
  width: 100% !important;
  height: 100% !important;
}

canvas.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.ril__caption,
.ril__toolbar {
  background-color: transparent;
  text-align: center;
}

.ril__captionContent {
  width: 100%;
}

.extend-row {
  padding: 15px 50px;
  border: 1px solid #e0e0e0;
  border-left: 0;
  border-right: 0;
}

.unit-list-notes p {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
}

.card.activity-log-card {
  padding: 15px;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}

.activity-log-card ul {
  padding: 0;
}

.activity-log-card ul li {
  padding-left: 15px;
  list-style-type: none;
  height: 45px;
  border-bottom: 1px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}

.activity-log-card ul li span {
  text-transform: capitalize;
}

.activity-log-card .card-header {
  border: none;
  padding: 0 !important;
  padding-bottom: 15px !important;
}

.activity-log-card .card-body {
  box-shadow: 0px 0px 3px 1px rgb(124 123 124 / 17%);
  padding: 0 !important;
}

.change-profile-card {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 50%;
}

.font-weight-600 {
  font-weight: 600;
}

/* Disput page css */
.citation-table {
  border-top: 1px solid #dee2e6 !important;
}

.overflow-auto {
  overflow: auto !important;
}

/* Utility css */
.min-w-200px.col {
  min-width: 200px;
  width: 200px;
  max-width: 20%;
}

@media (max-width: 768px) {
  .change-profile-card {
    width: 80%;
  }
}

@media (max-width: 1660px) {
  .unit-list-notes p {
    max-width: 120px;
  }
}

@media (max-width: 440px) {
  .min-w-200px.col {
    min-width: 135px;
    width: 200px;
    max-width: 50%;
  }
}

@media (max-width: 380px) {
  .ac-lots.col {
    max-width: 240px;
    min-width: 240px;
  }

  .lot-sharing-left-column.col {
    max-width: 240px;
    min-width: 240px;
  }
}

/* 3d button for self register page */
.pushable {
  background: #afb0bb;
  /* #6e78c4 */
  border-radius: 12px;
  border: none;
  padding: 3;
  cursor: pointer;
  outline-offset: 8px;
}

.front {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #6c757d;
  /* #303b93 */
  color: white;
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.parkingPermitHeader {
  flex: 1 !important;
  justify-content: center !important;
  display: flex !important;
  padding-left: 130px !important;
}

.CompanyHeader {
  justify-content: left !important;
}


.parkingPermitAddress {
  white-space: NOWRAP;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lpnIcon {
  display: flex;
  align-items: center;
}

.lpnIcon:after {
  content: "";
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  background-image: url("../img/layers.svg");
  background-size: cover;
  margin-left: 8px;
}

@media (max-width: 767px) {
  .parkingPermitHeader {
    padding-left: 0px !important;
  }
}
.citation-list-table .sc-fLQRDB.bNYFvk{
  max-height: 515px !important;
  min-height: 510px;
  height: 100%;
}
body:has(.pos-rel-overflow-hide){
  overflow-y: hidden !important;
}