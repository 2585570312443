.w-150px {
  width: 150px !important;
}
.bg-light-gray {
  background: #f2f2f2;
}
.form-control-sm {
  height: 30px !important;
  font-size: 12px !important;
}
.upfate-form .row {
  border-bottom: 1px dashed #e9e7e7;
}
.tiketupdate-icon {
  opacity: 0.5;
  position: absolute;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
}
.upfate-form .form-check .form-check-label {
  padding-left: 25px !important;
  margin-right: 5px !important;
}
.tiketupdate-icon.ticket-round-icon {
  top: 46%;
}

@media (max-width: 1270px) {
  .upfate-form .row {
    border-bottom: 1px dashed #e9e7e7;
    flex-direction: column;
  }
  .upfate-form .row .col-auto.col input {
    margin-left: 1rem;
  }
  .upfate-form label.pl-0.pl-md-5 {
    padding-left: 1rem !important;
  }
}

@media (max-width: 768px) {
  .upfate-form .row {
    flex-direction: column;
  }
}
