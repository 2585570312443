.fs-11px {
  font-size: 11px;
}
.fs-13px {
  font-size: 13px !important;
}
.w-30px {
  width: 30px;
}
.h-30px {
  height: 30px;
}
.btn-sm-new {
  height: 30px;
  font-size: 11px !important;
  line-height: 12px;
}
.min-w-100px {
  min-width: 100px;
}
.min-w-150px {
  min-width: 150px;
}
.btn-xs-new {
  height: 24px !important;
  font-size: 11px !important;
  line-height: 12px !important;
  padding: 0px 12px !important;
  border-radius: 0.25rem;
}
.btn.btn-xs {
  border: 1px solid #d2d2d2 !important;
  font-size: 11px;
  padding: 3px 10px;
}
.btn-xs-new-a {
  padding-top: 5px !important;
}
.justify-content-between {
  justify-content: space-between;
}
.dropdown-menu .dropdown-item:first-child:hover {
  border-radius: 10px 10px 0 0;
}
.dropdown-menu .dropdown-item:last-child:hover {
  border-radius: 0 0 10px 10px;
}

.dropdown {
  .dropdown-menu {
    .dropdown-item {
      padding: 5px 10px;
      font-size: 12px;
      &:hover,
      &:active {
        background: var(--bs-light);
      }
    }
  }
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  opacity: 0.5;
}
/* ========== Table Header ========== */
.rdt_TableHeadRow {
  min-height: 35px !important;
  height: 35px;
}

.citation-list-table .rdt_TableRow,
.citation-paid-table .rdt_TableRow {
  min-height: 33px !important;
  max-height: 33px;
}
.verify-audit-table.citation-list-table .rdt_TableRow,
.verify-audit-table.citation-paid-table .rdt_TableRow {
  min-height: 38px !important;
  max-height: 38px;
}

/* Table Row */
.rdt_TableRow {
  min-height: 44px !important;
  max-height: 44px;
  font-size: 12px !important;
}

// .mb-0.card {
//   .card-body {
//     /*  padding: 10px 10px 0 10px !important; */
//   }
// }

.card .rdt_Table {
  margin-top: 0;
}

// .eaqGUG {
//   /*   border: 1px solid #e1e1e6 !important;
//   border-top: 0 !important;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
// }
.active-permit-header {
  margin-bottom: 20px;
}
.sidebarFilter .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebarFilter .card .card-body {
  padding: 15px !important;
  flex: 1;
  overflow: auto;
}

.sidebarFilter .card .card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  padding: 10px 15px !important;
}

/* .rdt_Pagination {
  min-height: 40px !important;
} */

.exportButton {
  margin-top: 0;
}

/* .search-mb-0 .active-permit-header {
  margin-bottom: 0;
} */
.citation-list-table,
.citation-paid-table {
  border: 1px solid #e1e1e6 !important;
  border-top: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header Action Three Dot Dropdoan menu */
.buttons-dd .dropdown-toggle::after {
  display: none;
}

.filter-btn-bottom .filter-button {
  margin-top: 17px;
}
.filter-btn-bottom .header-search-button {
  margin: -8px 0;
}

// .citation-list-table.list-of-ravenue-table > div:first-child {
// }
.lpr-report-table.get-height.card {
  margin-bottom: 0;
}
